
import baseComponent from "@/scripts/baseComponent";
import vmMachineApi from '@/api/gpuHub/vmMachines';
import lazyLoadComponent from "@/scripts/lazyLoadComponent";
import SkeletonBox from "@/components/SkeletonBox";
import SkeletonBoxWithoutLoading from '@/components/SkeletonBoxWithoutLoading';
import { mapState } from "vuex";
import moment from 'moment';
import EventBus from "@/event/EventBus";
import { API_CALL_STATUS } from "@/constant/config";
import { colorGpuNodeColor, vmMachineStatusText, vmMachineStatus, vmMachineStatusColor }
    from "@/constant/vmMachineStatusConst";

export default {
    extends: baseComponent,
    components: {
        ColumnUser: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/User"),
            loading: SkeletonBox,
        }),
        ColumnBalance: lazyLoadComponent({
            componentFactory: () => import("@/views/components/column/Balance"),
            loading: SkeletonBox,
        }),
        detailMachine: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/detailVmMachine"),
            loading: SkeletonBox,
        }),
        paidTimes: lazyLoadComponent({
            componentFactory: () => import("@/views/components/common/PaidTimes"),
            loading: SkeletonBoxWithoutLoading,
        })
    },
    props: {
        packageItem: {
            type: Object,
            default() {
                return null;
            }
        }
    },
    watch: {
        packageItem() {
            this.fixedRentalDetailsViewByPackageData = [];
            if (this.packageItem != null) this.fixedRentalDetailsViewByPackage();
        }
    },
    data() {
        return {
            isLoading: false,
            colorGpuNodeColor: colorGpuNodeColor,
            vmMachineStatus: vmMachineStatus,
            vmMachineStatusColor: vmMachineStatusColor,
            capicityConst: {
                C30Gb: 32212254720,
                C50Gb: 53687091200
            },
            fixedRentalDetailsViewByPackageData: []
        };
    },
    computed: {
        ...mapState({
            userInfo: (state) => state.auth.user,
            rolesInfo: (state) => state.auth.roles,
            staffsInfo: (state) => state.staffs,
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
            userTagClassifyList: state => state.common.userClassifyAll,
            userTagClassifyDict: state => state.common.userClassifyAll.reduce(function (acc, cur, i) {
                acc[cur.id] = cur;
                return acc;
            }, {})
        }),
    },
    mounted() {
        this.fixedRentalDetailsViewByPackage();
    },
    methods: {
        // Fixed rental edit
        fixedRentalDetailsViewByPackage() {
            this.isLoading = true;
            let request = {
                packageId: this.packageItem.package,
                querySearch: '',
            }
            vmMachineApi.fixedRentalDetailsViewByPackage(request)
                .then((res) => {
                    try {
                        this.fixedRentalDetailsViewByPackageData = res.data;
                    } catch (error) {
                        console.log("getData -> error", error);
                    }
                    this.isLoading = false;
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            console.log("getData -> error", error)
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                    }
                    this.isLoading = false;
                });
        },
        bootingDuration(loadingTime, startTime) {
            try {
                if (!loadingTime || !startTime) return "-- -- -- --";
                var totalSecondsVal = moment.utc(startTime).diff(moment.utc(loadingTime), 'seconds', true)
                var totalDurations = moment.duration(totalSecondsVal, 'seconds');
                var durationFormat = `${(totalDurations._data.days > 0 ? 'D[d]' : '')}`;
                durationFormat += `${(totalDurations._data.hours > 0 ? 'H[h]' : '')}`;
                durationFormat += `${(totalDurations._data.minutes > 0 ? 'm[m]' : '')}`;
                return totalDurations.format(durationFormat, {
                    trim: false
                });
            } catch (err) {
                return "";
            }
        },
        getColorOfImageCapicity(value) {
            if (value > this.capicityConst.C30Gb && value <= this.capicityConst.C50Gb) {
                return "warning";
            }
            if (value > this.capicityConst.C50Gb) {
                return "danger";
            }
            return "info";
        },
    }
}